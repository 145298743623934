@import "./lib/_var.pcss";
@import "./lib/_mixin.pcss";
@import "./lib/_vegas.css";

.main-visual {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: calc(100vh - 50px);
  
  /*background: url(/assets/images/top/main_ph.jpg) no-repeat;
  background-size: cover;*/
  overflow: hidden;
  z-index: 1;
  
  @media (--sm-viewport) {
    top: 60px;
    height: calc(100vh - 60px);
  }
  @media (--md-viewport) {
    top: 90px;
    height: calc(100vh - 90px);
  }
  
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: url(/assets/images/top/main-overlay.png);
    width: 100%;
    height: 100%;
  }
  
  &__inner {
    height: 100%!important;
  }
  
  &__innerwrap {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  
  &__copy {
    position: absolute;
    bottom: 20%;
    left: 0;
    z-index: 1;
    display: inline-block;
    padding: 0 var(--xs-container-margin);
    top: 50%;
    transform: translateY(-40%);
    width: 100%;
    box-sizing: border-box;
    
    @media only screen and (max-width: 375px) {
      bottom: auto;
      top: 35%;
    }
  
    @media (--sm-viewport) {
      bottom: 30%;
      padding: 0 var(--sm-container-margin);
      left: 0;
      top: 50%;
      transform: translateY(-40%);
    }
    @media (--md-viewport) {
      width: auto;
      bottom: auto;
      left: auto;
      right: 3%;
      top: 50%;
      transform: translateY(-60%);
    }
    
    &--main {
      margin: 0;
      font-size: 32px;
      color: #fff;
      font-weight: bold;
      display: inline-block;
      line-height: 1.4;
      
      @media (--sm-viewport) {
        font-size: 53px;
      }
      @media (--md-viewport) {
        font-size: 63px;
      }
    }
    
    &--sub {
      margin: 0;
      font-size: 18px;
      color: #fff;
      margin-top: var(--sm-container-margin);
      font-weight: bold;
      display: inline-block;
      line-height: 1.7;
      
      @media (--sm-viewport) {
        font-size: 23px;
      }
      @media (--md-viewport) {
        margin-top: 80px;
      }
    }
  }
  
  &__scroll {
    position: absolute;
    bottom: 0px;
    z-index: 1;
  }
  
  .main-visual__scroll {
    @apply --button-reset;
    background: #d2d2d2;
    width: 66px;
    height: 66px;
    border-radius: 66px;
    position: relative;
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
    margin: auto;
    box-sizing:content-box;
    transition: background 0.3s;
    
    @media only screen and (max-width: 320px) {
      bottom: 80px;
    }
    @media only screen and (min-width: 321px) and (max-width: 480px) {
      bottom: 90px;
    }
    @media only screen and (min-width: 481px) and (max-width: 640px) {
      bottom: 110px;
    }
    @media (--sm-viewport) {
      bottom: 12%;
    }
    
    @media (--md-viewport) {
      &:hover,
      &:focus {
        background: #e4e3e3;
      }
    }
    
    span {
      @apply --invisible;
    }
    
    &:before {
      content: "\e906";
      @apply --unitec-iconset;
      color: #fff;
    }
    
    &:after {
      content: "";
      display: block;
      position: absolute;
      background: #d2d2d2;
      top: 0;
      left: 0;
      width: 66px;
      height: 66px;
      border-radius: 66px;
      animation: spread-scale 2s ease 1s infinite;
      z-index: -1;
    }
  }
}

@keyframes spread-scale {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }

  100% {
    transform: scale(1.3);
    opacity: 0;
  }
}


.main {
  margin-top: calc(100vh - 50px);
  overflow: hidden;
  position: relative;
  z-index: 1;
  
  @media (--sm-viewport) {
    margin-top: calc(100vh - 60px);
  }
  @media (--md-viewport) {
    margin-top: calc(100vh - 90px);
  }
  
  .main__inner {
    background: #fff;
  }
}


.column {
  background: #fff url(/assets/images/top/column_bg.jpg) no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
  
  .column__inner {
    padding-bottom: 60px;
    z-index: 1;
    position: relative;
  }
  
  &__btn {

  }
  
  &__items {
    
  }
  
  .items {
    margin-bottom: 40px;
    
    &__list {
      list-style: none;
      padding: 0;
      margin: 0;
      position: relative;
      
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url(/assets/images/top/column_item_bg.jpg) no-repeat center;
        background-size: cover;
        z-index: 1;
        opacity: 0;
        transition: opacity 1s;
      }
      
      &.on {
        &:after {
          opacity: 1;
        }
      }
    }
    &__item {
      padding: 0;
      width: 100%;
      position: relative;
      z-index: 2;
      
      &:nth-child(2n) {
        .item__link {
          background: rgba(36,119,204,0.6);
        }
      }
    }
    .item__link {
      position: relative;
      box-sizing: border-box;
      padding: 20px 30px 70px;
      display: block;
      font-size: 18px;
      font-weight: bold;
      color: #fff;
      background: rgba(0,88,233,0.6);
      background-size: cover;
      background-position: center;
      text-decoration: none;
      transition: background 0.5s;
      
      @media (--sm-viewport) {
        height: 400px;
      }
      @media (--xs-viewport) {
        padding: 20px 20px;
      }
      
      .item__title {
        position: relative;
        z-index: 2;
        margin-bottom: 20px;
        display: block;
        font-size: 18px;
        
        @media (--xs-viewport) {
          margin: 38px 0 0;
          line-height: 1.4;
        }
      }
      .item__lead,
      .item__more {
        display: block;
        position: relative;
        z-index: 2;
        font-size: 14px;
        opacity: 0;
        transition: opacity 0.5s;
        
        @media (--xs-viewport) {
          display: none;
        }
      }
      .item__more {
        margin-top: 15px;
        text-decoration: underline;
      }
      
      .item__column {
        position: absolute;
        bottom: 25px;
        right: 15px;
        z-index: 2;
        font-weight: normal;
        
        @media (--xs-viewport) {
          bottom: auto;
          right: auto;
          top: 15px;
          left: 20px;
        }
      }
      
      .item__icon {
        font-family: "Josefin Sans", sans-serif;
        font-size: 12px;
        display: inline-block;
        width: 40px;
        text-align: center;
        white-space: nowrap;
        margin-right: 5px;
        vertical-align: bottom;
        
        &:after {
          content: "";
          display: block;
          background: url(/assets/images/top/column_icon_nami.png) no-repeat;
          background-size: 100%;
          width: 40px;
          height: 20px;
          margin-top: 4px;
        }
        
        @media (--xs-viewport) {
          width: 35px;
          font-size: 10px;
          
          &:after {
            width: 35px;
            height: 18px;
          }
        }
      }

      .item__date {
        display: inline-block;
        
        span {
          font-size: 24px;
          line-height: 1.2;
          font-family: "Delius Swash Caps", cursive;
          display: block;
          text-align: center;
        }
        
        @media (--xs-viewport) {
          span {
            font-size: 15px;
          }
        }
      }
      
      @media (--md-viewport) {
        &:hover,
        &:focus {
          background: var(--sub-accent-color);
          
          .item__lead,
          .item__more {
            opacity: 1;
          }
        }
      }
    }
  }
}

.company,
.environment {
  position:relative;
  z-index: 1;
  background: #fff;
  
  &__inner {
    padding-bottom: 70px;
    z-index: 1;
    position: relative;
    
    @media (--md-viewport) {
      padding-bottom: 150px;
    }
  }
  
  &__imagebg {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 320px;
    width: 100%;
    z-index: 1;
    overflow: hidden;
    
    @media (--md-viewport) {
      background: url(/assets/images/top/company_ph.jpg) no-repeat;
      background-size: cover;
      background-position: left center;
      position: absolute;
      top: 0;
      right: 0;
      left: auto;
      width: 78%;
      height: 100%;
      padding: 0;
    }
    
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: -40%;
      width: 160%;
      height: 100%;
      
      background: url(/assets/images/top/company_ph.jpg) no-repeat;
      background-size: cover;
      background-position: center;
      
      @media (--md-viewport) {
        display: none;
      }
    }
  }
  
  &__lead {
    color: var(--accent-color);
    font-size: 16px;

    margin-top: 20px;
    margin-bottom: 50px;
    
    padding: 0 var(--sm-container-margin);
    padding-top: 200px;
    @media (--md-viewport) {
      padding: 0;
    }
    
    p {
      margin: 0 0 2em 0;
    }
  }
}

.environment {
  &__imagebg {
    @media (--md-viewport) {
      left: 0;
      right: auto;
      width: 68%;
      background-image: url(/assets/images/top/environment_ph.jpg);
      background-position: right center;
    }
    &:after {
      background-image: url(/assets/images/top/environment_ph.jpg);
    }
  }
}


.business {
  
  .lock & {
    &:before {
      content: "";
      background: url(/assets/images/top/business_bg.jpg) no-repeat;
      background-size: cover;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  
  
  &__inner {
    padding-bottom: 70px;
    z-index: 1;
    position: relative;
  }
  
  &__btn {
    margin-top: 40px;
    
    @media (--sm-viewport) {
      margin-top: 0;
    }
  }

  &__title {

  }
  
  .businesslist {
    justify-content: center;
    
    &__title {
      color: #fff;
      font-weight: bold;
      margin: 0;
      padding: 35px 0 20px;
    }
    
    &__item {
      text-align: center;
      padding: 0 20px;
    }
    &__lead {
      color: #fff;
      margin-bottom: 0;
      
      @media (--sm-viewport) {
        margin-bottom: 45px;
      }
    }
    
    &__link {
      text-decoration: none;
      
      
      
      .businesslist__icon {
        border-radius: 100px;
        width: 150px;
        height: 150px;
        margin: auto;
        position: relative;
        margin-bottom: 25px;
        box-shadow: 0px 0px 0px 0px rgba(0,0,0,0);
        transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
        
        @media (--sm-viewport) {
          width: 170px;
          height: 170px;
        }
        
        img {
          width: 45%;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
        }
        
        &--works {
          background-color: #22acfb;
          &:hover {
            background-color: #5cb7ec;
          }
        }
        &--qualifications {
          background-color: #fdaa1a;
          &:hover {
            background-color: #ffce77;
          }
          img {
            width: 60%;
          }
        }
        &--results {
          background-color: #75d956;
          &:hover {
            background-color: #80ee5e;
          }
        }
      }
      
      @media (--md-viewport) {
        &:hover,
        &:focus {
          .businesslist__icon {
            box-shadow: 0px 5px 40px -7px rgba(0,0,0,0.7);
            transform: translateY(-1%) scale(1.06);
            
            &--works {
              background-color: #5cb7ec;
            }
            &--qualifications {
              background-color: #ffce77;
            }
            &--results {
              background-color: #80ee5e;
            }
          }
        }
      }
    }
  }
  
}

.recruit {
  position: relative;
  z-index: 1;
  background: #fff;
  
  .recruit__inner {
    padding-bottom: 0px;
    position: relative;
    z-index: 1;
  }
  
  @media (--md-viewport) {
    padding-bottom: 10px;
  }
  
  &__lead {
    color: var(--accent-color);
    font-size: 16px;
    margin-bottom: 30px;
    margin-top: 220px;
    padding: 0 var(--sm-container-margin);

    p {
      margin: 0 0 2em 0;
    }
    
    @media (--sm-viewport) {
      margin-top: 50%;
      margin-bottom: 50px;
      padding: 0;
    }
    @media (--md-viewport) {
      color: var(--accent-color);
      line-height: 2;
      font-size: 16px;
      margin-bottom: 180px;
      margin-top: -100px;
    }
  }
  
  &__btn {
    a {
      margin-bottom: 20px;
    }
  }
  
  &__imagebg {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 320px;
    width: 100%;
    z-index: 1;
    
    @media (--sm-viewport) {
      padding-top: 60%;
    }
    @media (--md-viewport) {
      background: url(/assets/images/top/recruit_ph.jpg) no-repeat;
      background-size: contain;
      background-position: right top;
      position: absolute;
      top: 0;
      right: 0;
      left: auto;
      width: 78%;
      height: 100%;
      z-index: 1;
      padding: 0;
    }
    
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      
      background: url(/assets/images/top/recruit_ph_sp.jpg) no-repeat;
      background-size: cover;
      background-position: top;
      
      @media (--md-viewport) {
        display: none;
      }
    }
  }
  
}


.triangle {
  position: relative;
  height: 130px;
  
  @media (--sm-viewport) {
    height: 170px;
  }
  
  &__title {
    font-size: 33px;
    font-family: var(--font-nimbus);
    // letter-spacing: 0.06em;
    color: #fff;
    text-align: center;
    padding-top: 20px;
    margin: 0;
    position: relative;
    z-index: 1;
    line-height: 1;
    
    @media (--sm-viewport) {
      font-size: 40px;
      padding-top: 40px;
    }
    
    span {
      display: block;
      margin-top: 5px;
      font-size: 16px;
      font-weight: bold;
      
      @media (--sm-viewport) {
        margin-top: 10px;
      }
    }
  }
  
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 130px 135px 0;
    border-color: rgba(40,140,200,0.8) transparent transparent transparent;
    @media (--sm-viewport) {
      border-width: 170px 200px 0 200px;
    }
  }
  
  &--imagebg {
    height: 135px;
    
    @media (--sm-viewport) {
      height: 210px;
    }
    
    &:before {
      display: none;
    }
    background: url(/assets/images/top/triangle_bg.png) no-repeat center bottom;
  }
}

.column__title {
  &.triangle {
    .triangle__title {
      color: var(--accent-color);
      
      @media (--sm-viewport) {
        padding-top: 60px;
      }
    }
  }
}
.business__title {
  &.triangle {
    .triangle__title {
      color: var(--accent-color);
    }
    &:before {
      border-color: #fff transparent transparent transparent;
    }
  }
}
.environment__title {
  &.triangle {
    .triangle__title {
      font-size: 28px;
      @media (--sm-viewport) {
        font-size: 36px;
      }
    }
  }
}

.company-info {
  background: #fff;
  position: relative;
  overflow: hidden;
  
  &__outer {

  }
  
  &__inner {

  }
  
  &__lead {
    padding: 20px 5% 30px;
    font-size: 16px;
    line-height: 2;
    text-align: center;
    
    @media (--md-viewport) {
      padding: 70px 0;
    }
  }
  
  &__profile {
    background: #f2f5f7;
    padding: 25px 30px;
    margin: 50px 0;

    @media (--md-viewport) {
      padding: 25px 50px;
    }
  }
  
  &__logo {
    width: 50%;
    margin: auto;
    
    @media (--sm-viewport) {
      width: auto;
      padding-right: 30px;
    }
    @media (--md-viewport) {
      padding-right: 80px;
    }
  }
  
  &__detail {
    font-size: 16px;
    margin: 10px 0 20px 0;
    
    @media (--sm-viewport) {
      margin: 0;
    }
    
    span {
      display: inline-block;
    }
  }
  
  &__contact {
    width: 100%;
    
    @media (--sm-viewport) {
      width: auto;
    }
  }
  
  &__image {
    img {
      width: 100%;
      vertical-align: bottom;
    }
  }
  &__map {
    iframe {
      vertical-align: bottom;
    }
  }
}